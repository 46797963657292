import React, { Component } from "react";

//Constants
import { API_WEB_URLS } from "../../constants/constAPI";

//Store
import { BePositive, Fn_FillListData, Fn_GetReport } from "../../store/functions";

import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../Masters/datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as XLSX from "xlsx";
import Select from "react-select";
function getLastMonth() {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
class reports_BalanceLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NewId : 0,
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      formData: {
        F_LedgerMaster: "",
      },
      success_msg: false,
      dynamic_title: "",
      dynamic_description: "",

      F_CollegeMaster: 0,
      ViewType: 0,
      DateAsOn: "",
      page: 1,
      sizePerPage: 10,
      productDataLia: [],
      header_dd: [],
      success_dlg: false,
      OpeningClosing: [{}],
      rows: [
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
      ],
      cols: [],
      F_Type: 0,
      selectedMulti: [],
      productDataAssest: [],
    };
    this.obj = this;
    this.breadCrumbTitle = "Reports";
    this.breadCrumbItem = "Ledger Report";
    this.modalTitle = "Balance Sheet";
    this.rtPage_Add = "add-BalanceSheet";
    this.rtPage_Edit = "edit-BalanceSheet";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/BalanceSheet/Id/0";
    this.API_URL_SAVE = API_WEB_URLS.BalanceLedger + "/0/token";
    this.API_URL_SAVE_Assest = API_WEB_URLS.BalanceSheetAssest + "/0/token";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.syno = this.syno.bind(this);
    this.handleMulti = this.handleMulti.bind(this);
    this.onsearchLedger = this.onsearchLedger.bind(this);
    this.exportToExcel = this.exportToExcel.bind(this);
  }

  componentDidMount() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    this.setState({
      name: obj.username,
      email: obj.email,
      aid: obj.uid,
      role: obj.role,
    });
    Fn_FillListData(
      this.obj,
      "ledger",
      API_WEB_URLS.MASTER + "/0/token/LedgerForAccounting/Id/0"
    );
  
    const { id } = this.props.match.params;
    if (id) {
      this.setState({ NewId: id });
     
    } else {
     
     
    }
  }
  componentDidUpdate(prevProps, prevState) {
    // Check if state.NewId has changed
    if (this.state.ledger !== prevState.ledger) {
      if(this.state.NewId>0){
        const obj = this.state.ledger.find((data)=>data.Id == this.state.NewId)
        this.setState({
          LedgerName : obj.Name
        },()=>{
          let vformData = new FormData();

          vformData.append("FromDate", getCurrentDate());
          vformData.append("ToDate", getCurrentDate());
      
          vformData.append("Id", this.state.NewId);
          Fn_GetReport(
            this.obj,
            { arguList: { id: 0, formData: vformData } },
            "GetOpeningClosingLedger/0/token",
            "OpeningClosing",
            true
          );
      
          Fn_GetReport(
            this.obj,
            { arguList: { id: 0, formData: vformData } },
            this.API_URL_SAVE,
            "productDataLia",
            true
          );
        })
      }
    }
  }
  exportToExcel = () => {
    // let vformData = new FormData();

    // vformData.append("FromDate", this.state.FromDate);
    // vformData.append("ToDate",  this.state.ToDate);

    // Fn_ExportExcel(this.obj, { arguList: { id: 0, formData: vformData } }, "DMRExcel/0/token", "DMRTransaction", true);

    const obj = JSON.parse(sessionStorage.getItem("authUser"));

    const worksheet = XLSX.utils.json_to_sheet(this.state.productDataLia);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AccountingLedgerExcel");
    XLSX.writeFile(workbook, `AccountingLedgerExcel.xlsx`);
  };

  onsearchLedger = event => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    let vformData = new FormData();
    vformData.append("Search", event.target.value);
    console.log("Coming in voucher search");
    Fn_GetReport(
      this.obj,
      { arguList: { id: this.state.aid, formData: vformData } },
      "SearchLedger/0/token",
      "ledger",
      true
    );
  };
  syno() {
    this.setState({ success_msg: false, success_dlg: false });
  }

  async btnSave_onClick(event, formData) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    const { FromDate, ToDate } = formData;
    
    this.setState({ FromDate });
  
    let vformData = new FormData();
    vformData.append("FromDate", FromDate);
    vformData.append("ToDate", ToDate);
    vformData.append("Id", this.state.NewId > 0 ? this.state.NewId : this.state.F_LedgerMaster || 0);
  
    try {
      
      await Fn_GetReport(
        this,
        { arguList: { id: 0, formData: vformData } },
        "GetOpeningClosingLedger/0/token",
        "OpeningClosing",
        true
      );
  
      
      await Fn_GetReport(
        this,
        { arguList: { id: 0, formData: vformData } },
        this.API_URL_SAVE,
        "productDataLia",
        true
      );
  
      
      console.log("Reports fetched successfully");
    } catch (error) {
      // Handle any errors from the report fetching
      console.error("Error fetching reports:", error);
    }
  }
  

  handleMulti = selectedMulti => {
    this.setState({ selectedMulti });
    console.log(JSON.stringify(selectedMulti));
    var multiData = JSON.stringify(selectedMulti);
  };

  render() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    let columnNames = this.state.rows[0];

    // Assuming the first row has column names
   
    const columns = [
      
      {
        dataField: "VoucherDate",
        text: "VoucherDate",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "VoucherNo",
        text: "VoucherNo",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "Remarks",
        text: "Remarks",
        sort: true,
        footer: columnData => <div></div>,
      },
     
      {
        dataField: "Narration",
        text: "Narration",
        sort: true,
        footer: columnData => <div></div>,
      },
     
      {
        dataField: "CrAmount",
        text: "CrAmount",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "DrAmount",
        text: "DrAmount",
        sort: true,
        footer: columnData => <div></div>,
      },
      
      {
        dataField: "Balance",
        text: "Balance",
        sort: true,
        formatter: (cellContent, row) => {
          return(
            BePositive(row.Balance)
          )
        },
       
      },

      // {
      //   dataField: "action",
      //   text: "View",

      //   formatter: (cellContent, row) => {
      //     return (
      //       <button
      //         type="button"
      //         //disabled={row.IsRefund? true : false}
      //         className="btn btn-danger btn-xs"
      //         onClick={() => this.getledgergroupsdata(row.Id, row.Name)}
      //       >
      //         View
      //       </button>
      //     );
      //   },
      // },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 25,
      totalSize: this.state.productDataLia.length, // replace later with size(customers),
      custom: true,
    };

    // const pageOptionsCr = {
    //   sizePerPage: 10,
    //   totalSize: this.state.productDataLia.length, // replace later with size(customers),
    //   custom: true,
    // }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: "150", value: 150 },
      { text: "200", value: 200 },
      { text: "All", value: this.state.productDataLia.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;
    const { selectedMulti } = this.state;
    return (
      <>
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.breadCrumbTitle}
                breadcrumbItem={this.breadCrumbItem}
              />
              <Row>
                <Col lg="14">
                  <Card>
                    <CardBody>
                      <AvForm
                        className="needs-validation"
                        onValidSubmit={this.btnSave_onClick}
                      >
                        <Row>
                          <Col style={{ maxWidth: "4%" }} className="mb-3">
                            <label
                              htmlFor="lastName"
                              className="col-form-label"
                            >
                              Search
                            </label>
                          </Col>
                          <Col sm="2">
                            <AvField
                              name="SearchLedger"
                              label=""
                              value={this.state.formData.SearchLedger}
                              onChange={this.onsearchLedger}
                              placeholder="Search Ledger"
                              errorMessage="Enter Ledger "
                              type="text"
                              className="form-control"
                            />
                          </Col>
                          {this.state.LedgerName != null ? <Col sm='2' className="m-2"> <span style={{fontWeight:'bold'}}>Ledger : {this.state.LedgerName}</span></Col> : <>
                            <Col style={{ maxWidth: "4%" }} className="mb-3">
                            <label
                              htmlFor="tilldatee"
                              className="col-form-label"
                            >
                              {" "}
                              Ledger
                            </label>
                          </Col>
                          <Col sm="2">
                            <Select
                              options={
                                this.state.ledger &&
                                this.state.ledger.map(item => ({
                                  value: item.Id,
                                  label: item.Name,
                                }))
                              }
                              onChange={e => {
                                this.setState({
                                  F_LedgerMaster: e.value,
                                });
                              }}
                              placeholder="select ledger"
                              isSearchable
                            />
                          </Col>
                          </>}
                         

                          <Col style={{ maxWidth: "4%" }} className="mb-3">
                            <label
                              htmlFor="tilldatee"
                              className="col-form-label"
                            >
                              {" "}
                              From
                            </label>
                          </Col>
                          <Col sm="2" className="mb-3">
                            <AvField
                              name="FromDate"
                              label=""
                              value={
                                this.state.formData.FromDate == undefined
                                  ? getCurrentDate()
                                  : this.state.formData.FromDate
                              }
                              placeholder="From Date"
                              errorMessage="Select Date "
                              validate={{ required: { value: true } }}
                              type="date"
                              className="form-control"
                            />
                          </Col>
                          <Col style={{ maxWidth: "4%" }} className="mb-3">
                            <label
                              htmlFor="tilldatee"
                              className="col-form-label"
                            >
                              {" "}
                              To
                            </label>
                          </Col>
                          <Col sm="2" className="mb-3">
                            <AvField
                              name="ToDate"
                              label=""
                              value={
                                this.state.formData.ToDate == undefined
                                  ? getCurrentDate()
                                  : this.state.formData.ToDate
                              }
                              placeholder="to Date"
                              errorMessage="Select Date "
                              validate={{ required: { value: true } }}
                              type="date"
                              className="form-control"
                            />
                          </Col>

                          <Col sm="2" className="mb-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1 waves-effect waves-light"
                            >
                              {" "}
                              View{" "}
                            </Button>
                            <></>
                            <Button
                              type="Button"
                              onClick={this.exportToExcel}
                              color="danger"
                              className="mr-1 waves-effect waves-light"
                            >
                              {" "}
                              Excel{" "}
                            </Button>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col xs="3">
                  <Card>
                    <CardBody>
                      <div className="text-center">
                        <h4>₹{BePositive(this.state.OpeningClosing[0].OpeningBalance)}</h4>
                        <p>Opening Balance</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="3">
                  <Card>
                    <CardBody>
                      <div className="text-center">
                        <h4>₹{this.state.OpeningClosing[0].TotalCredit}</h4>
                        <p>Total Credit</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="3">
                  <Card>
                    <CardBody>
                      <div className="text-center">
                        <h4>₹{this.state.OpeningClosing[0].TotalDebit}</h4>
                        <p>Total Debit</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="3">
                  <Card>
                    <CardBody>
                      <div className="text-center">
                        <h4>₹{BePositive(this.state.OpeningClosing[0].ClosingBalance)}</h4>
                        <p>Closing Balance</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory({
                          ...pageOptions,
                          sizePerPageList: [
                            { text: "25", value: 25 },
                            { text: "50", value: 50 },
                            { text: "100", value: 100 },
                          ],
                        })}
                        keyField="id"
                        columns={columns}
                        data={this.state.productDataLia}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={this.state.productDataLia}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default compose(container)(reports_BalanceLedger);
