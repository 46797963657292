import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Constants
import { API_WEB_URLS } from "../constants/constAPI";
import * as base_acTypes from "./actionTypes";
import { API_HELPER } from "helpers/ApiHelper";
/** Common Functions **/
//Change state value
export const Fn_ChangeStateValue = (obj, name, value) => {
  obj.setState({ [name]: value });
};

export const Fn_FillListData = (obj, gridName, apiURL) => {
  const request = {
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          [gridName]: response.data.dataList,
          isProgress: false,
        });
        
         
        
        if (gridName == "InvoiceDataL") {
          Fn_FillListData(
            obj,
            "ItemName",
            API_WEB_URLS.MASTER +
              "/0/token/ItemType/F_GroupMaster/" +
              response.data.dataList[0].F_GroupMaster
          );
        }
        if (gridName == "PlyPartyH") {
          obj.setState({
            IsCalculated:
              response.data.dataList[0].Calculated == true ? true : false,
          });
        }
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };
  obj.props.callFill_GridData(request);
};

export const Fn_FillListDataLedger = (obj, gridName, apiURL) => {
  const request = {
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          [gridName]: response.data.dataList,
          isProgress: false,
          Name: response.data.dataList[0].Name,
          GSTIN: response.data.dataList[0].GSTNo,
          PhoneNo: response.data.dataList[0].Phone,
          Mobile: response.data.dataList[0].Mobile,
          Email: response.data.dataList[0].Email,
          Address1: response.data.dataList[0].Address1,
          Address2: response.data.dataList[0].Address2,
          ZIP: response.data.dataList[0].Zip,
          CountryId: response.data.dataList[0].F_CountryMaster,
          StateId: response.data.dataList[0].F_StateMaster,
          CityId: response.data.dataList[0].F_CityMaster,
          LedgerGroupIds: response.data.dataList[0].F_LedgerGroupMaster,
        });
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };
  obj.props.callFill_GridData(request);
};

export const Fn_GetReport = (
  obj,
  data,
  apiURL,
  gridName,
  isMultiPart = false
) => {
  const { arguList } = data;
  
  return new Promise((resolve, reject) => {
    const request = {
      arguList: arguList,
      apiURL: apiURL,
      callback: response => {
        if (response && response.status === 200 && response.data) {
          if (gridName === "productData") {
            obj.setState({
              [gridName]: response.data.response,
              isProgress: false,
            });
          } else if (gridName === 'gridData1') {
            let openingBalance = {};
            let closingBalance = {};
            const gridData = [];
        
            response.data.response.forEach(item => {
              if (item.PartyName === 'OPENING') {
                openingBalance = item;
              } else if (item.PartyName === 'CLOSING') {
                closingBalance = item;
              } else {
                gridData.push(item);
              }
            });
        
            obj.setState({ openingBalance, closingBalance, gridData });
          } else {
            obj.setState({
              [gridName]: response.data.response,
              rows: [Object.keys(response.data.response[0])],
              isProgress: false,
            });
          }
          
          resolve(response.data.response);
        } else {
          obj.setState({
            [gridName]: [],
            isProgress: false,
          });
          showToastWithCloseButton("warning", "Data not found.");
          reject(new Error("Data not found"));
        }
      },
    };

    obj.props.callAdd_Data_Multipart(request);
  });
};
export const Fn_CheckBalance = async(obj, id) => {

  if(id>0){
  const formData = new FormData();
  formData.append('Id', id);


  let AuthUser2 = function() {
      return API_HELPER.apiPOST_Multipart(API_WEB_URLS.BASE+"GetBalance/0/token" , formData).then(token => { return token } )
    }
    let userToken2 = await AuthUser2();
    obj.setState({Balance : userToken2.data.response[0].Balance, isloading : false})

  //   
  //   sessionStorage.setItem("Balance",userToken2.data.response[0].Balance);

  }
};

export const Fn_FillListDataGlobal = (obj, gridName, apiURL) => {
  const request = {
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          [gridName]: response.data.dataList,
          VoucherNo: response.data.dataList[0].VoucherNo,
          isProgress: false,
        });

        if (response.data.dataList[0].IsVoucherNoEnable == true) {
          obj.vdate.current.focus();
        } else {
          obj.vno.current.focus();
        }
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };
  obj.props.callFill_GridData(request);
};

export const Fn_FillListData2 = (obj, gridName, apiURL) => {
  const request = {
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState(
          {
            [gridName]: response.data.dataList,
            isProgress: false,
            InvoiceId: response.data.dataList[0].Id,
            VoucherNo: response.data.dataList[0].VoucherNo,
            VoucherDate: response.data.dataList[0].VoucherDate,
            PartyDetail: response.data.dataList[0].PartyDetail,
            DiscountType: response.data.dataList[0].DiscountType,
            Rate: response.data.dataList[0].Rate,
            Info: response.data.dataList[0].Info,
            EntryMode: response.data.dataList[0].EntryMode,
            TotalQuantity: response.data.dataList[0].TotalQty,

            GrossAmount: response.data.dataList[0].GrossAmount,
            TaxAmount: response.data.dataList[0].TaxationAmount,
            NetAmount: response.data.dataList[0].NetAmount,
            LedgerId: response.data.dataList[0].F_LedgerMaster,
          }

          // Fn_FillListData3(obj , response.data.dataList[0].F_LedgerMaster , ""),
          // Fn_FillListData4(obj , response.data.dataList[0].F_LedgerMaster , ""),
          // Fn_FillListData5(obj , response.data.dataList[0].F_LedgerMaster , ""),
          // Fn_FillListData(obj, "PlyPartyH", API_WEB_URLS.MASTER + "/0/token/PlyPartyH/Id/"+response.data.dataList[0].F_LedgerMaster)
        );

        if (response.data.dataList[0].IsCancelled == true) {
          obj.setState({
            canceleinvoiceBtn: true,
            cancelinvoiceName: "Already Cancelled!",
          });
        } else {
          obj.setState({
            canceleinvoiceBtn: false,
            cancelinvoiceName: "Cancel Invoice",
          });
        }

        if (response.data.dataList[0].Irn != null) {
          obj.setState({
            edit: true,
            editbtnname: "Cant Edit!",
            einvoice: true,
          });
        } else {
          obj.setState({ edit: false, editbtnname: "EDIT", einvoice: false });
        }

        if (response.data.dataList[0].IsDiff == true) {
          obj.setState({
            IsCon: false,
          });
        }
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };

  obj.props.callFill_GridData(request);
};

export const Fn_FillListData3 = (obj, F_LedgerMaster, apiURL) => {
  var Name = "Ply";

  const request2 = {
    apiURL: "Masters/0/token/GetItemByPlyPartyDescription/Id/" + F_LedgerMaster,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          [Name]: response.data.dataList,
        });
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };

  obj.props.callFill_GridData(request2);
};

export const Fn_FillListDataInvoice = (obj, apiURL) => {
  var Name = "Invoice";

  const request2 = {
    apiURL: "Masters/0/token/Invoice/Id/0",
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          [Name]: response.data.dataList,
        });
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };

  obj.props.callFill_GridData(request2);
};

export const Fn_FillListData4 = (obj, F_LedgerMaster, apiURL) => {
  var Name = "PrintDesc";

  const request2 = {
    apiURL: "Masters/0/token/GetPrintPartyDescription/Id/" + F_LedgerMaster,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          [Name]: response.data.dataList,
        });
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };

  obj.props.callFill_GridData(request2);
};

export const Fn_FillListData5 = (obj, F_LedgerMaster, apiURL) => {
  var Name = "ConsigneeAddress";

  const request2 = {
    apiURL: "Masters/0/token/LedgerConsigneeAddress/Id/" + F_LedgerMaster,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          [Name]: response.data.dataList,
        });

        if (obj.state.Add1 == true) {
          obj.setState({
            Add1: true,
            OtherAddress: response.data.dataList[0].Address1,
            Add2: false,
            Add3: false,
          });
        } else if (obj.state.Add2 == true) {
          obj.setState({
            Add1: false,
            OtherAddress: response.data.dataList[0].Address2,
            Add2: true,
            Add3: false,
          });
        } else if (obj.state.Add3 == true) {
          obj.setState({
            Add1: false,
            OtherAddress: response.data.dataList[0].Address3,
            Add2: false,
            Add3: true,
          });
        }
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying grid data"
        );
      }
    },
  };

  obj.props.callFill_GridData(request2);
};

export const Fn_DisplayData = (obj, id, apiURL, check) => {
  const request = {
    id: id,
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        console.log(response.data.dataList[0].Id);
        obj.setState({
          formData: response.data.dataList[0],
          id: response.data.dataList[0].Id,
          TotalAmount: response.data.dataList[0].TotalAmount,
          DenominationAmount: response.data.dataList[0].TotalAmount,
          F_VoucherTypeMaster: response.data.dataList[0].F_VoucherTypeMaster,
          CashType: response.data.dataList[0].CashType,
        });
        if (check === 'Group') {
          console.log('in group',response.data.dataList[0].F_SubLedgerGroup)
          obj.setState(prevState => ({
            toggleSwitch: response.data.dataList[0].F_SubLedgerGroup == 0 ? true : false,
            formData: {
              ...prevState.formData,
              F_SubLedgerGroup: response.data.dataList[0].F_SubLedgerGroup,
              F_LedgerGroupMaster: response.data.dataList[0].F_LedgerGroupMaster,
            },
          }));
        }
        
        else if (check) {
          
          Fn_FillListData(
            obj,
            "VoucherL",
            API_WEB_URLS.MASTER +
              "/0/token/GetVoucherLDetails/Id/" +
              response.data.dataList[0].Id
          );
        }
        // if(apiURL===API_WEB_URLS.COMPANY)
        // {
        //     //Fill city drop down
        //     if(response.data.data[0].stateId && response.data.data[0].stateId>0)
        //         Fn_FillListData(obj,'lstCity',API_WEB_URLS.COMMON_GETLIST_DROPDOWN + '/cities/stateId/'+ response.data.data[0].stateId);
        // }
        // else if(apiURL===API_WEB_URLS.PROPERTY_FLOOR || apiURL===API_WEB_URLS.SUB_COMPANY)
        // {
        //     //Fill city drop down
        //     if(response.data.data[0].blockId && response.data.data[0].blockId>0)
        //         Fn_FillListData(obj,'lstBlock',API_WEB_URLS.COMMON_GETLIST_DROPDOWN + '/blocks/propertyId/'+ response.data.data[0].propertyId);
        // }
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying data"
        );
      }
    },
  };
  obj.props.callGet_Data(request);
};

export const Fn_DisplayDataLedger = (obj, id, apiURL) => {
  const request = {
    id: id,
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200 && response.data) {
        obj.setState({
          formData: response.data.dataList[0],
          Name: response.data.dataList[0].Name,
          GSTIN: response.data.dataList[0].GSTNo,
          PhoneNo: response.data.dataList[0].Phone,
          Mobile: response.data.dataList[0].Mobile,
          Email: response.data.dataList[0].Email,
          Address1: response.data.dataList[0].Address1,
          Address2: response.data.dataList[0].Address2,
          ZIP: response.data.dataList[0].Zip,
          CountryId: response.data.dataList[0].F_CountryMaster,
          StateId: response.data.dataList[0].F_StateMaster,
          CityId: response.data.dataList[0].F_CityMaster,
          LedgerGroupIds: response.data.dataList[0].F_LedgerGroupMaster,
        });
        // if(apiURL===API_WEB_URLS.COMPANY)
        // {
        //     //Fill city drop down
        //     if(response.data.data[0].stateId && response.data.data[0].stateId>0)
        //         Fn_FillListData(obj,'lstCity',API_WEB_URLS.COMMON_GETLIST_DROPDOWN + '/cities/stateId/'+ response.data.data[0].stateId);
        // }
        // else if(apiURL===API_WEB_URLS.PROPERTY_FLOOR || apiURL===API_WEB_URLS.SUB_COMPANY)
        // {
        //     //Fill city drop down
        //     if(response.data.data[0].blockId && response.data.data[0].blockId>0)
        //         Fn_FillListData(obj,'lstBlock',API_WEB_URLS.COMMON_GETLIST_DROPDOWN + '/blocks/propertyId/'+ response.data.data[0].propertyId);
        // }
      } else {
        showToastWithCloseButton(
          "error",
          "Some error occurred while displaying data"
        );
      }
    },
  };
  obj.props.callGet_Data(request);
};


export const Fn_AddEditData = (
  obj,
  data,
  apiURL,
  pushFormName,
  isMultiPart = false,
  getid,
  getmsg,
  newArr,
  arr1
) => {
  return new Promise((resolve, reject) => {
    const { arguList } = data;
    const request = {
      arguList: arguList,
      apiURL: apiURL,
      callback: async response => {
        if (response && response.status === 200) {
          if (getid == "VoucherId") {
            if (response.data.response[0].Id > 0) {
              obj.setState({
                success_msg: true,
              });
            } else {
              obj.setState({
                failed: true,
                ReBalance: response.data.response[0].Name,
              });
            }
          }
          if (getid == "BenId") {
            if (response.data.data.id > 0) {
              obj.setState({
                success_msg_Ben: true,
                modal_backdrop: false,
                F_BankMaster: -1,
                IFSC: "",
                AccountHolderName: "",
                AccountNo: "",
              });
  
              Fn_FillListData(
                obj,
                "gridData",
                API_WEB_URLS.MASTER + "/0/token/BeneficiaryMaster/Id/" + getmsg
              );
  
              let vformData = new FormData();
  
              vformData.append("F_AccountUsage", 4);
              let GetUrl = function () {
                return API_HELPER.apiPOST_Multipart(
                  API_WEB_URLS.BASE + "GetSMSUrl/0/token",
                  vformData
                ).then(token => {
                  return token;
                });
              };
  
              let userToken = GetUrl();
              userToken.then(function (result) {
                var url = result.data.response[0].Url;
                var to = obj.state.MobileNo;
                var name = obj.state.AccountHolderName;
                var accountno = obj.state.AccountNo;
                var ifsc = obj.state.IFSC;
  
                url = url.replace("{to}", to);
                url = url.replace("{name}", name);
                url = url.replace("{accountno}", accountno);
                url = url.replace("{ifsc}", ifsc);
  
                let SendOtp = function () {
                  return API_HELPER.apiGET_OTP(url).then(token => {
                    return token;
                  });
                };
  
                let res = SendOtp();
              });
            }
          } else if (getid == "SenderId") {
            obj.setState({ modal_backdrop: false });
            Fn_FillListData(
              obj,
              "SenderId",
              API_WEB_URLS.MASTER + "/0/token/414/" + getmsg
            );
          } else if (getid == "verify") {
            Fn_FillListData(
              obj,
              "gridData",
              API_WEB_URLS.MASTER + "/0/token/BeneficiaryMaster/Id/" + getmsg
            );
  
            obj.setState({
              IsBenVerified: true,
              success_verify: true,
              AccountHolderName: response.data.data.name,
            });
          } else if (getid == "VoucherL") {
            obj.setState({
              IdVoucherH : response.data.response[0].Id
            },()=>{
              console.log(obj.state.IdVoucherH)
            })
            let vformData = new FormData();
            vformData.append("F_VoucherH", response.data.response[0].Id);
            vformData.append("Data", JSON.stringify(getmsg));
            await Fn_AddEditData(
              obj,
              { arguList: { id: 0, formData: vformData } },
              "VoucherL/0/token",
              "#",
              true
            );
  
            if (newArr != null && obj.state.IsDenomination &&  obj.state.F_VoucherTypeMaster == 12 ||
              obj.state.F_VoucherTypeMaster == 13) {
              console.log(obj.state.IsDenomination , newArr)
              let vformData1 = new FormData();
              vformData1.append("F_VoucherH", response.data.response[0].Id);
            
              vformData1.append("Data", JSON.stringify(newArr));
  
              await Fn_AddEditData(
                obj,
                { arguList: { id: 0, formData: vformData1 } },
                "Denomination/0/token",
                "#",
                true
              );
              obj.props.history.push(pushFormName);
            }
            if(arr1 !=null && obj.state.IsDenominationRe &&  obj.state.F_VoucherTypeMaster == 12 ||
              obj.state.F_VoucherTypeMaster == 13){
              let vformData1 = new FormData();
              vformData1.append("F_VoucherH", response.data.response[0].Id);
              vformData1.append("IsRe", true);
              vformData1.append("Data", JSON.stringify(arr1));
  
               Fn_AddEditData(
                obj,
                { arguList: { id: 0, formData: vformData1 } },
                "Denomination/0/token",
                "#",
                true
              );
              obj.props.history.push(pushFormName);
            }
            
            obj.props.history.push(pushFormName);
          } else if (getid == "rusu") {
            let vformData = new FormData();
            vformData.append("Id", 1);
            vformData.append("FromDate", obj.state.FromDate);
            vformData.append("ToDate", obj.state.ToDate);
  
            Fn_GetReport(
              obj,
              { arguList: { id: obj.uid, formData: vformData } },
              "Recharges/0/token",
              "productData",
              true
            );
  
            obj.setState({
              commissionmodal: false,
              F_RetailerComission: 0,
              F_DistributorComission: 0,
              F_SDistributorComission: 0,
              F_MDistributorComission: 0,
              F_RechargeId: 0,
              Charges: 0,
              Amount: 0,
              UTR: "",
              F_TransactionStatusCode: 0,
              success_dlg: true,
            });
          } else if (getid == "rusudmr") {
            let vformData = new FormData();
            vformData.append("Id", 1);
            vformData.append("FromDate", obj.state.FromDate);
            vformData.append("ToDate", obj.state.ToDate);
  
            Fn_GetReport(
              obj,
              { arguList: { id: obj.uid, formData: vformData } },
              "DMRTran/0/token",
              "productData",
              true
            );
  
            obj.setState({
              commissionmodal: false,
              F_RetailerComission: 0,
              F_DistributorComission: 0,
              F_SDistributorComission: 0,
              F_MDistributorComission: 0,
              F_RechargeId: 0,
              Charges: 0,
              Amount: 0,
              UTR: "",
              F_TransactionStatusCode: 0,
              success_dlg: true,
            });
          } else if (getid == "updateutr") {
            let vformData = new FormData();
            vformData.append("Id", 0);
            vformData.append("FromDate", obj.state.FromDate);
            vformData.append("ToDate", obj.state.ToDate);
  
            vformData.append(
              "Mode",
              obj.state.ModeId == "" ? 0 : obj.state.ModeId
            );
            vformData.append(
              "F_MemberMaster",
              obj.state.F_MemberMaster == "" ? 0 : obj.state.F_MemberMaster
            );
  
            Fn_GetReport(
              obj,
              { arguList: { id: 0, formData: vformData } },
              "UTRUpdateList/0/token",
              "productData",
              true
            );
  
            obj.setState({
              success_dlg: true,
            });
          } else if (getid == "rusuindo") {
            let vformData = new FormData();
            vformData.append("Id", 1);
            vformData.append("FromDate", obj.state.FromDate);
            vformData.append("ToDate", obj.state.ToDate);
  
            Fn_GetReport(
              obj,
              { arguList: { id: obj.uid, formData: vformData } },
              "IndoNepalTran/0/token",
              "productData",
              true
            );
  
            obj.setState({
              commissionmodal: false,
              F_RetailerComission: 0,
              F_DistributorComission: 0,
              F_SDistributorComission: 0,
              F_MDistributorComission: 0,
              F_RechargeId: 0,
              Charges: 0,
              Amount: 0,
              UTR: "",
              F_TransactionStatusCode: 0,
              success_dlg: true,
            });
          } else if (getid == "aeps") {
            let vformData = new FormData();
            vformData.append("Id", 1);
            vformData.append("FromDate", obj.state.FromDate);
            vformData.append("ToDate", obj.state.ToDate);
  
            Fn_GetReport(
              obj,
              { arguList: { id: obj.uid, formData: vformData } },
              "AEPSTran/0/token",
              "productData",
              true
            );
  
            obj.setState({
              commissionmodal: false,
              F_RetailerComission: 0,
              F_DistributorComission: 0,
              F_SDistributorComission: 0,
              F_MDistributorComission: 0,
              F_AEPSId: 0,
              Charges: 0,
              Amount: 0,
              UTR: "",
              F_TransactionStatusCode: 0,
              success_dlg: true,
            });
          } else if (getid == "rusufund") {
            let vformData = new FormData();
            vformData.append("Id", obj.state.uid);
            vformData.append("FromDate", obj.state.FromDate);
            vformData.append("ToDate", obj.state.ToDate);
            vformData.append(
              "Status",
              obj.state.Status == undefined ? 0 : obj.state.Status
            );
  
            Fn_GetReport(
              obj,
              { arguList: { id: obj.state.uid, formData: vformData } },
              "FundRequestTran/0/token",
              "productData",
              true
            );
  
            obj.setState({
              commissionmodal: false,
              Remarks: "",
              F_FundRequestId: 0,
              success_dlg: true,
              IsApproved: 0,
            });
          } else if (getid == "serid") {
            obj.setState({
              Search: "",
              memberlist: [],
              success_msg: true,
              success_dlg: true,
            });
          } else if (getid == "chat") {
            Fn_FillListData(
              obj,
              "test",
              API_WEB_URLS.MASTER + "/0/token/GetMessages/Id/" + getmsg
            );
            obj.setState({ curMessage: "" });
          } else {
            obj.setState({
              kit: response.data.data.id,
              success_msg: true,
              success_dlg: true,
              isloading: false,
            });
          }

          obj.setState({
            [getid]: response.data.data.id,
            isloading: false,
          });

          if (arguList.id === 0) {
            obj.setState({
              // success_msg : true
            });
            showToastWithCloseButton("success", "Data added successfully");
          } else {
            obj.setState({
              [getmsg]: true,
              success_dlg: true,
              confirm_alert_Approve: false,
              isloading: false,
            });

            showToastWithCloseButton("success", "Data updated successfully");
          }
          obj.props.history.push(pushFormName);
          resolve(response);
        } else {
          if (arguList.id === 0) {
            if (getid == "BenId") {
              obj.setState({
                loading: false,
              });
              showToastWithCloseButton("error", "Benfeciary already exists.");
            } else {
              showToastWithCloseButton(
                "error",
                "Some error occurred while updating data"
              );
            }
          } else if (getid == "verify") {
            if (response.status == -3) {
              obj.setState({ insufficienfund: true });
            } else {
              obj.setState({
                error_ben: true,
                Reasonun: response.data.data.name,
              });
            }
          } else
            showToastWithCloseButton(
              "error",
              "Some error occurred while updating data"
            );
          reject(response);
        }
      },
    };

    if (arguList.id === 0) {
      if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
      else obj.props.callAdd_Data(request);
    } else {
      if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
      else obj.props.callEdit_Data(request);
    }
  });
};
export const BePositive = (val) =>{
  const str = val > 0 ? val + ' Dr' : Math.abs(val) + ' Cr'
  return str;
}
export const Fn_AddEditDataH = (
  obj,
  data,
  apiURL,
  pushFormName,
  isMultiPart = false,
  getid,
  data1,
  data2,
  data3,
  data4,
  api1,
  api2,
  formname
) => {
  const { arguList } = data;
  const request = {
    arguList: arguList,
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200) {
        obj.setState({
          [getid]: response.data.data.id,
          InvoiceId: response.data.data.id,
          success_msg: true,
          save: true,
          einvoice: false,
          InvoiceSel: false,
          cancel: true,
          add: false,
          print: false,
        });
        Fn_FillListDataInvoice(obj, "");

        let vformData2 = new FormData();
        vformData2.append("F_InvoiceMasterH", response.data.data.id);
        vformData2.append("Data", data2);

        Fn_AddEditData(
          obj,
          { arguList: { id: 0, formData: vformData2 } },
          api1,
          formname,
          true
        );

        let vformData = new FormData();
        vformData.append("F_InvoiceMasterH", response.data.data.id);
        vformData.append("Data", data1);

        Fn_AddEditData(
          obj,
          { arguList: { id: 0, formData: vformData } },
          api2,
          formname,
          true
        );

        let vformData4 = new FormData();
        vformData4.append("F_InvoiceMasterH", response.data.data.id);
        vformData4.append("Data", JSON.stringify(data4));

        // Fn_AddEditData(obj, { arguList: { id: 0, formData: vformData4 } }, "InvoiceTaxableAmount/0/token", "addinvoice", true);

        if (data3.length > 0) {
          let vformData3 = new FormData();

          vformData3.append("F_InvoiceMasterH", response.data.data.id);
          vformData3.append("Data", JSON.stringify(data3));

          Fn_AddEditData(
            obj,
            { arguList: { id: 0, formData: vformData3 } },
            "InvoiceCharge/0/token",
            "addinvoice_new",
            true
          );
        }

        if (arguList.id === 0)
          showToastWithCloseButton("success", "Data added successfully");
        else showToastWithCloseButton("success", "Data updated successfully");
        obj.props.history.push(pushFormName);
      } else {
        if (arguList.id === 0)
          showToastWithCloseButton("error", "Same Invoice No. used!");
        else if (arguList.id == -1)
          showToastWithCloseButton("error", "Same Invoice No. used!");
        else showToastWithCloseButton("error", "Same Invoice No. used");
      }
    },
  };

  if (arguList.id === 0)
    if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
    else obj.props.callAdd_Data(request);
  else if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
  else obj.props.callEdit_Data(request);
};

export const Fn_ChangePassword = (
  obj,
  data,
  apiURL,
  pushFormName,
  isMultiPart = false
) => {
  const { arguList } = data;
  const request = {
    arguList: arguList,
    apiURL: apiURL,
    callback: response => {
      if (response.data.response[0].Id > 0) {
        if (arguList.id === 0)
          showToastWithCloseButton("success", "Password Changed Successfully");
        else showToastWithCloseButton("success", "Data updated successfully");
        obj.props.history.push(pushFormName);
      } else {
        if (arguList.id === 0)
          showToastWithCloseButton("error", "Old Password is wrong.");
        else
          showToastWithCloseButton(
            "error",
            "Some error occurred while updating data"
          );
      }
    },
  };

  if (arguList.id === 0)
    if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
    else obj.props.callAdd_Data(request);
  else if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
  else obj.props.callEdit_Data(request);
};

export const Fn_UserLogin = (
  obj,
  data,
  apiURL,
  pushFormName,
  isMultiPart = false
) => {
  const { arguList } = data;
  const request = {
    arguList: arguList,
    apiURL: apiURL,
    callback: response => {
      if (response && response.status === 200) {
        if (arguList.id === 0)
          showToastWithCloseButton("success", "Logged in successfully");
        else showToastWithCloseButton("success", "Logged in successfully");
        obj.props.history.push(pushFormName);
      } else {
        if (arguList.id === 0)
          showToastWithCloseButton("error", "UserName or Password Incorrect!");
        else
          showToastWithCloseButton("error", "UserName or Password Incorrect!");
      }
    },
  };

  if (arguList.id === 0)
    if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
    else obj.props.callAdd_Data(request);
  else if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
  else obj.props.callEdit_Data(request);
};

export const Fn_DeleteData = (obj, id, apiURL, apiURL_Display) => {
  const request = {
    id: id,
    apiURL: apiURL,
    callback: response => {
      //console.log(response.status);
      if (response && response.status === 200) {
        obj.setState({
          confirm_alert: false,
          success_dlg: true,
          dynamic_title: "Deleted",
          dynamic_description: "Selected data has been deleted.",
        });
        showToastWithCloseButton("success", "Data deleted successfully");
        if (apiURL_Display) Fn_FillListData(obj, "gridData", apiURL_Display);
        Fn_FillListData(obj, "Invoice", apiURL_Display);
        obj.add();
      } else {
        obj.setState({
          confirm_alert: false,
          dynamic_title: "Deleted",
          dynamic_description: "Selected data has been deleted.",
        });
        showToastWithCloseButton(
          "error",
          "Some error occurred while deleting data"
        );
      }
    },
  };
  obj.props.callDelete_Data(request);
};

export const togglemodal = (obj, formData) => {
  obj.setState(prevState => ({
    modal: !prevState.modal,
    selectedFormData: formData ? formData : {},
  }));
};

export const toggleDeleteConfirm = (obj, formData, value) => {
  obj.setState({
    confirm_alert: value,
    selectedFormData: formData ? formData : {},
  });
};

export const toggleDeleteSuccess = (obj, value) => {
  obj.setState({ success_dlg: value });
};

function showToastWithCloseButton(toastType, message) {
  toastr.options = {
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    progressBar: true,
    timeOut: 2000,
  };
  if (toastType == "success") toastr.success(message);
  else if (toastType == "error") toastr.error(message);
}
/***/
